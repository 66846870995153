<template>
  <div class="body fixed mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header new">
      <a class="icon iconButton third" @click.prevent="goBack">
        <font-awesome-icon
          icon="fa-solid fa-chevron-left"
          size="lg"
        ></font-awesome-icon>
      </a>
      <span class="fL" v-if="article.categoryType === 'campaigns'">熱門活動</span>
      <span class="fL" v-else-if="article.categoryType === 'functions'">使用攻略</span>
      <span class="fL" v-else>最新消息</span>
      <div class="icon iconButton"></div>
    </section>
    <!-- Main -->
    <section class="main new">
      <div v-if="article.imageUrl" class="img cover"><img :src="article.imageUrl" /></div>
      <div class="flexV">
        <span class="fXXL third">{{ article.title }}</span>
        <span class="fM">{{ article.departmentName }}</span>
        <div class="flexH">
          <span class="fS gray" v-if="article.startTs">{{
            tsToData(article.startTs)
          }}</span>
          <span class="fS gray">・</span>
          <span class="fS gray">{{ article.categoryName }}</span>
        </div>
      </div>
      <span class="fM" v-html="article.htmlContent"></span>
      <!-- <a class="button rounded">
                <span class="fM">更多資訊</span>
            </a> -->
    </section>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  name: "",
  data() {
    return {
        apiHost: process.env.VUE_APP_API_HOST,
        merchantId: process.env.VUE_APP_MERCHANT_ID,    
        article: {
            title: "",
            departmentName: "",
            htmlContent: "",
            startTs: "",
            categoryName: "",
            imageUrl: "",
        }
    };
  },
  mounted() {
    this.findArticle();
  },
  computed: {
    ...mapGetters({
      articles: "article/getArticles",
    }),
  },
  methods: {
    tsToData(ts) {
      if (!ts) return "";
      return moment.unix(ts).utcOffset(8).format('YYYY-MM-DD');
    },
    dummyArticle() {
      let result = {
        title: "測試最新消息",
        htmlContent:
          '<h2><span style="color: rgb(0, 102, 204);">卡好女王節</span></h2><p>三大支付，消費滿千送百</p>',
        imageUrl:
          "https://storage.googleapis.com/appointed_store_images/tycard2022/home/8caf3ea4-3316-48c5-9c34-46baee1c9c51.png",
        status: "approved",
        startTs: 1651593600,
        endTs: 1654358399,
        departmentName: "規劃發展科",
        categoryName: "其它",
      };
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: result });
        }, 300);
      });
    },
    callArticleAPI() {
      var config = {
        method: "get",
        url: `${this.apiHost}/articles/v1/merchants/${this.merchantId}/articles/${this.$route.params.id}`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getArticle() {
        // return this.dummyArticle()
        return this.callArticleAPI()
        .then(res => {
            return res.data;
        })
        .catch(err => console.log(err));
    },
    findArticle() {
        this.getArticle()
        .then(res => {
            this.article = res;
        })
    },
    goBack() {
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$route.meta.mode === 'guest'
            ? this.$router.push('/guest')
            : this.$router.push("/home");
      }
        
    },
  },
};
</script>
